<template>
  <div class="action-wrap">
    <div class="action-header">
      <div
        class="action-header-wrap tw-container tw-mx-auto tw-flex tw-relative md:tw-pt-18px md:tw-pb-24px lg:tw-pt-20px lg:tw-pb-28px xl:tw-pt-30px xl:tw-pb-40px dark:tw-flex-col"
      >
        <span
          class="action-header-title tw-block tw-text-center tw-font-extrabold tw-relative tw-mx-auto tw-text-70px tw-pt-40px md:tw-pt-0 md:tw-absolute md:tw-text-38px lg:tw-text-44px xl:tw-text-64px dark:tw-text-black dark:tw-text-66px dark:tw-relative"
        >
          Акции
          <span class="tw-text-white dark:tw-text-black">2022</span>
        </span>
        <div
          class="action-header-img tw-bg-no-repeat tw-bg-contain tw-order-2 tw-mb-30px md:tw-mb-0 md:tw-order-1 dark:tw-hidden"
        ></div>
        <span
          class="action-header-text tw-block tw-font-bold tw-order-1 tw-text-42px tw-text-center md:tw-text-left md:tw-order-2 md:tw-pl-12px md:tw-pt-82px md:tw-text-22px lg:tw-pl-14px lg:tw-pt-96px lg:tw-text-26px xl:tw-pl-20px xl:tw-pt-140px xl:tw-text-36px dark:tw-text-black dark:tw-text-40px dark:tw-pt-20px"
        >
          Экономьте с
          <span class="tw-text-white dark:tw-text-black">
            Единой картой петербуржца
          </span>
          при&nbsp;оплате проезда, коммунальных услуг и не только!
        </span>
      </div>
    </div>

    <div
      class="action-content tw-container tw-mx-auto tw-bg-no-repeat tw-mt-30px md:tw-mt-68px md:tw-px-14px xl:tw-mt-100px xl:tw-px-20px dark:tw-mt-20px dark:tw-text-black"
    >
      <div
        class="action-block block-1 tw-flex tw-mx-auto tw-mb-40px md:tw-pb-6px md:tw-pt-14px md:tw-mb-50px xl:tw-pb-10px xl:tw-pt-20px xl:tw-mb-100px dark:tw-mb-20px"
      >
        <div
          class="action-block-inner block-text tw-relative tw-pl-100px tw-mb-14px md:tw-mb-0 md:tw-pl-60px lg:tw-pl-68px md:tw-pr-14px xl:tw-pl-100px xl:tw-pr-20px dark:tw-pl-0 dark:tw-pr-0"
        >
          <div class="action-mobile-img-title">
            <div class="action-block-circle-img tw-absolute dark:tw-hidden">
              <img :src="require('@/static/images/action/m.svg')" />
            </div>
            <span
              class="action-block-title tw-block tw-font-semibold tw-text-24px tw-pb-6px md:tw-text-18px xl:tw-text-24px dark:tw-text-26px"
            >
              Проезд в метро стал ещё дешевле!
            </span>
          </div>
          <span
            class="action-block-subtitle tw-block tw-font-semibold tw-text-18px tw-pb-14px md:tw-text-16px xl:tw-text-18px dark:tw-text-22px"
          >
            До 34 рублей выгоды с каждой поездки
          </span>
          <span
            class="action-block-text tw-block tw-text-16px tw-pb-14px md:tw-text-14px xl:tw-text-16px xl:tw-pb-16px dark:tw-text-20px"
          >
            Акция платежной системы «Мир» по сниженной стоимости проезда в
            метрополитене продлена на 2022 год. Для держателей Единой карты
            петербуржца действует самый выгодный тариф!
          </span>
          <span
            class="action-block-text tw-block tw-text-16px tw-pb-14px md:tw-text-14px xl:tw-text-16px xl:tw-pb-10px dark:tw-text-20px"
          >
            Цена за одну поездку в метрополитене составит:
          </span>
          <div
            class="action-block-list-circle tw-text-16px tw-pl-28px md:tw-text-14px md:tw-pb-12px md:tw-pl-28px lg:tw-pb-14px lg:tw-pl-40px xl:tw-text-16px xl:tw-pb-20px xl:tw-pl-60px dark:tw-text-20px"
          >
            <ul>
              <li
                class="tw-relative tw-pl-24px tw-mb-10px md:tw-pl-20px md:tw-mb-10px xl:tw-pl-30px xl:tw-mb-14px"
              >
                31 рубль при оплате смартфоном с привязанной Единой картой
                петербуржца
              </li>
              <li
                class="tw-relative tw-pl-24px tw-mb-10px md:tw-pl-20px md:tw-mb-10px xl:tw-pl-30px"
              >
                35 рублей при оплате пластиковой картой
              </li>
            </ul>
          </div>
        </div>
        <div
          class="action-block-inner block-img tw-bg-no-repeat tw-bg-contain dark:tw-hidden"
        ></div>
      </div>

      <div
        class="action-block block-2 tw-flex tw-mx-auto tw-mb-40px md:tw-py-8px md:tw-mb-0 xl:tw-py-10px xl:tw-mb-20px dark:tw-mb-20px"
      >
        <div
          class="action-block-inner block-img tw-bg-no-repeat tw-bg-contain tw-order-2 md:tw-order-1 dark:tw-hidden"
        ></div>
        <div
          class="action-block-inner block-text tw-relative tw-order-1 tw-pl-100px tw-mb-14px md:tw-mb-0 md:tw-order-2 md:tw-pl-60px md:tw-pr-40px lg:tw-pl-102px xl:tw-pr-60px xl:tw-pl-150px dark:tw-pl-0 dark:tw-pr-0"
        >
          <div class="action-mobile-img-title">
            <div class="action-block-circle-img tw-absolute dark:tw-hidden">
              <img :src="require('@/static/images/action/zkh.svg')" />
            </div>
            <span
              class="action-block-title tw-block tw-font-semibold tw-text-24px tw-pb-6px md:tw-text-18px xl:tw-text-24px dark:tw-text-26px"
            >
              Кешбэк 4% за оплату ЖКХ
            </span>
          </div>
          <span
            class="action-block-text tw-block tw-text-16px tw-pb-14px md:tw-text-14px xl:tw-text-16px dark:tw-text-20px"
          >
            Условия акции:
          </span>
          <div
            class="action-block-list-num tw-text-16px tw-pl-28px tw-pb-14px md:tw-text-14px md:tw-pb-14px md:tw-pl-40px xl:tw-text-16px xl:tw-pb-20px xl:tw-pl-60px dark:tw-text-20px"
          >
            <ul>
              <li
                class="tw-relative tw-pl-24px tw-mb-14px md:tw-pl-20px md:tw-mb-14px xl:tw-pl-30px xl:tw-mb-20px"
                data-number="1"
              >
                Зарегистрируйте Единую карту петербуржца в программе лояльности
                НСПК «Мир» на сайте privetmir.ru, в приложении «Привет, Мир!»
                или прямо здесь:
                <button
                  class="tw-block tw-border tw-border-solid tw-rounded-lg tw-px-14px tw-py-6px tw-mt-8px md:tw-px-14px md:tw-py-6px md:tw-text-14px md:tw-mt-8px md:tw-mb-14px xl:tw-px-20px xl:tw-py-8px xl:tw-text-16px xl:tw-mt-10px xl:tw-mb-20px dark:tw-text-20px dark:tw-underline"
                  @click="navigateTo('https://ekp.spb.ru/mir')"
                >
                  Зарегистрировать
                </button>
              </li>
              <li
                class="tw-relative tw-pl-24px tw-mb-14px md:tw-pl-20px md:tw-mb-14px xl:tw-pl-30px xl:tw-mb-20px"
                data-number="2"
              >
                Оплачивайте Единой картой петербуржца коммунальные услуги на
                сайтах или в приложениях организаций ЖКХ
              </li>
              <li
                class="tw-relative tw-pl-24px tw-mb-14px md:tw-pl-20px md:tw-mb-14px xl:tw-pl-30px xl:tw-mb-20px"
                data-number="3"
              >
                Получайте кешбэк 4% (но не больше 300 рублей в месяц)
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="action-block block-3 tw-flex tw-mx-auto md:tw-pt-8px md:tw-pb-14px md:tw-mb-28px xl:tw-pt-10px xl:tw-pb-20px xl:tw-mb-40px dark:tw-mb-20px"
      >
        <div
          class="action-block-inner block-text tw-flex tw-justify-center tw-flex-col tw-items-start tw-relative tw-pl-100px md:tw-pl-60px md:tw-pr-14px lg:tw-pl-90px xl:tw-pl-130px xl:tw-pr-20px dark:tw-pl-0 dark:tw-pr-0"
        >
          <div class="action-mobile-img-title">
            <div class="action-block-circle-img tw-absolute dark:tw-hidden">
              <img :src="require('@/static/images/action/pr.svg')" />
            </div>
            <span
              class="action-block-title tw-block tw-font-semibold tw-text-24px tw-pb-6px md:tw-text-18px xl:tw-text-24px dark:tw-text-26px"
            >
              Скидки и поощрения
            </span>
          </div>
          <span
            class="action-block-subtitle tw-block tw-font-semibold tw-text-18px tw-pb-14px md:tw-text-16px xl:tw-text-18px dark:tw-text-22px"
          >
            У Единой карты петербуржца уже более 400 партнёров
          </span>
          <span
            class="action-block-text tw-block tw-text-16px tw-pb-14px md:tw-text-14px xl:tw-text-18px dark:tw-text-20px"
          >
            Оплачивайте товары и услуги со скидкой до 50%
          </span>
          <button
            class="tw-block tw-border tw-border-solid tw-rounded-lg tw-px-14px tw-py-6px md:tw-px-14px md:tw-py-6px md:tw-text-14px xl:tw-px-20px xl:tw-py-8px xl:tw-text-16px dark:tw-text-20px dark:tw-underline"
            @click="navigateTo('https://ekp.spb.ru/capabilities/loyalty')"
          >
            Список партнёров и бонусные программы
          </button>
        </div>
        <div
          class="action-block-inner block-img tw-bg-no-repeat tw-bg-contain tw-hidden md:tw-block dark:tw-hidden"
        ></div>
      </div>
    </div>

    <div
      class="action-footer tw-border-t tw-border-solid tw-border-gray-400 tw-mt-40px tw-pb-34px tw-pt-28px md:tw-mt-14px md:tw-py-34px xl:tw-mt-20px xl:tw-py-50px dark:tw-border-black dark:tw-text-black"
    >
      <div
        class="action-footer-wrap tw-container tw-mx-auto tw-flex tw-flex-wrap"
      >
        <span
          class="action-footer-mobile-title tw-block tw-text-center md:tw-hidden tw-font-semibold tw-text-36px tw-mb-10px md:tw-text-22px lg:tw-text-24px xl:tw-text-36px dark:tw-hidden"
        >
          У Вас еще нет Единой карты петербуржца?
        </span>
        <div
          class="action-footer-img tw-bg-no-repeat tw-bg-contain dark:tw-hidden"
        ></div>
        <div
          class="block-text tw-content-center tw-flex tw-flex-col tw-pl-28px md:tw-pl-28px xl:tw-pl-40px dark:tw-pl-0"
        >
          <span
            class="action-footer-title tw-hidden md:tw-block tw-font-semibold tw-text-36px md:tw-text-22px lg:tw-text-24px xl:tw-text-36px dark:tw-text-36px dark:tw-block"
          >
            У Вас еще нет Единой карты петербуржца?
          </span>
          <span
            class="action-footer-text tw-block tw-font-normal tw-mt-8px tw-text-18px md:tw-mt-8px lg:tw-mt-14px md:tw-text-16px lg:tw-text-18px xl:tw-mt-20px xl:tw-text-24px dark:tw-text-24px"
          >
            Вы можете оформить карту в одном из банков-партнеров Единой карты
            петербуржца.
          </span>
          <span
            class="action-footer-text tw-block tw-font-normal tw-mt-8px tw-text-18px md:tw-text-16px lg:tw-text-18px xl:tw-text-24px dark:tw-text-24px"
          >
            Узнайте условия получения карты на странице
            <a href="https://ekp.spb.ru/getcard">«Как получить карту»</a>
            Интернет-портала Единой карты петербуржца.
          </span>
          <!-- <div
            class="logo-wrap tw-flex tw-flex-wrap tw-justify-between tw-h-auto tw-mt-auto md:tw-justify-start md:tw-mt-16px lg:tw-mt-20px xl:tw-mt-30px dark:tw-hidden"
          >
            <img
              :src="require('@/static/images/action/vtb.svg')"
              class="logo-vtb md:tw-mr-28px xl:tw-mr-40px"
            />
            <img
              :src="require('@/static/images/action/spb.svg')"
              class="logo-spb md:tw-mr-28px xl:tw-mr-40px"
            />
            <img
              :src="require('@/static/images/action/sber.svg')"
              class="logo-sber"
            />
          </div> -->
        </div>
      </div>
    </div>
    <footer
      class="tw-bg-gray-300 tw-h-50px tw-flex tw-justify-center tw-items-center tw-text-gray-600 tw-px-4 tw-text-center"
    >
      Сайт разработан АО "АИСА ИТ-Сервис", 2022
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    navigateTo(link) {
      location.href = link
    },
  },
}
</script>

<style lang="less" scoped>
.action-wrap {
  color: #455a64;
  .action-header {
    background-color: #63c2ab;
    color: #455a64;
    .action-header-wrap {
      .action-header-title {
        top: 55px;
        left: 0;
        right: 0;
      }
      .action-header-img {
        background-image: url(./static/images/action/header-metro.svg);
        background-position: center right;
        height: 330px;
        width: 45%;
      }
      .action-header-text {
        width: 55%;
      }
    }
  }
  .action-content {
    background-image: url(./static/images/action/back-lines-way.svg);
    background-size: 40%;
    background-position: 28px 22px;
    .action-block {
      &.block-1 {
        .action-block-circle-img {
          top: 0;
          left: -10px;
        }
        .block-img {
          background-image: url(./static/images/action/block-1.svg);
          background-position: top left;
          width: 40%;
        }
        .block-text {
          width: 60%;
        }
      }
      &.block-2 {
        .action-block-circle-img {
          top: 0;
          left: 30px;
        }
        .block-img {
          background-image: url(./static/images/action/block-2.svg);
          background-position: center right;
          width: 35%;
        }
        .block-text {
          width: 65%;
        }
      }
      &.block-3 {
        .action-block-circle-img {
          top: 130px;
          left: 10px;
        }
        .block-img {
          background-image: url(./static/images/action/block-3.svg);
          background-position: top center;
          height: 430px;
          width: 50%;
        }
        .block-text {
          width: 50%;
        }
      }
      button {
        border-color: #1090cb;
        color: #1090cb;
      }
      button:hover {
        background: #1090cb;
        color: #fff;
      }
      button:focus {
        outline: none;
      }
      .action-block-list-circle li::before,
      .action-block-list-num li::before {
        display: block;
        position: absolute;
      }
      .action-block-list-circle li::before {
        content: '';
        background-color: #4e85d7;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        left: 0;
        top: 6px;
      }
      .action-block-list-num li::before {
        content: attr(data-number);
        left: 0;
        top: 0;
        color: #63c2ab;
        font-size: 18px;
        font-weight: 700;
      }
      .action-block-text {
        line-height: 24px;
      }
    }
  }
  .action-footer {
    .action-footer-img {
      background-image: url(./static/images/action/footer-card.svg);
      background-position: top right;
      width: 30%;
      height: 226px;
    }
    .block-text {
      width: 70%;
    }
  }

  @media (max-width: 1699px) {
    .action-header {
      .action-header-wrap {
        .action-header-title {
          top: 20px;
          left: 0;
          right: 0;
        }
        .action-header-img {
          height: 224px;
        }
      }
    }
    .action-content {
      background-size: 43%;
      background-position: 14px 20px;
      .action-block {
        &.block-1 {
          .action-block-circle-img {
            top: 0;
            left: -4px;
          }
        }
        &.block-2 {
          .action-block-circle-img {
            top: 0;
            left: 16px;
          }
          .block-img {
            width: 38%;
          }
          .block-text {
            width: 62%;
          }
        }
        &.block-3 {
          .action-block-circle-img {
            top: 82px;
            left: 0;
          }
          .block-img {
            height: 306px;
          }
        }
        .action-block-circle-img img {
          width: 48px;
        }
        .action-block-list-circle li::before {
          width: 8px;
          height: 8px;
          top: 6px;
        }
        .action-block-list-num li::before {
          font-size: 14px;
        }
        .action-block-text {
          line-height: 24px;
        }
      }
    }
    .action-footer {
      .action-footer-img {
        height: 154px;
      }
      .logo-wrap {
        .logo-vtb {
          width: 66px;
        }
        .logo-spb {
          width: 128px;
        }
        .logo-sber {
          width: 163px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .action-header {
      .action-header-wrap {
        .action-header-title {
          top: 30px;
          left: 0;
          right: 0;
        }
        .action-header-img {
          height: 192px;
        }
      }
    }
    .action-content {
      background: none;
      .action-block {
        &.block-1 {
          .action-block-circle-img {
            left: 0;
          }
        }
        &.block-2 {
          .action-block-circle-img {
            left: 0;
          }
        }
        &.block-3 {
          .action-block-circle-img {
            top: 40px;
            left: 0;
          }
          .block-img {
            height: 256px;
          }
        }
        .action-block-circle-img img {
          width: 48px;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    .action-header {
      .action-header-wrap {
        flex-direction: column;
        align-items: center;
        .action-header-title {
          top: 0;
        }
        .action-header-img {
          width: 92%;
          background-position: center;
          min-height: 100%;
          height: 402px;
        }
        .action-header-text {
          width: 92%;
        }
      }
    }
    .action-content {
      .action-block {
        flex-direction: column;
        width: 92%;
        &.block-1,
        &.block-2,
        &.block-3 {
          .block-img,
          .block-text {
            width: 100%;
          }
          .block-img {
            height: 300px;
            background-position: center;
          }
          .action-block-circle-img img {
            width: 70px;
          }
          .action-block-circle-img {
            top: 0;
            left: 0;
          }
        }
        .action-block-list-circle li::before {
          top: 8px;
        }
        .action-block-list-num li::before {
          top: 2px;
        }
      }
    }
    .action-footer {
      .action-footer-wrap {
        width: 92%;
        .action-footer-mobile-title {
          width: 100%;
        }
        .action-footer-img {
          width: 46%;
          height: 226px;
        }
        .block-text {
          width: 54%;
          .logo-wrap {
            .logo-vtb {
              width: 60px;
            }
            .logo-spb {
              width: 116px;
            }
            .logo-sber {
              width: 147px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 719px) {
    .action-header {
      .action-header-wrap {
        .action-header-title {
          top: 0;
          left: 0;
          right: 0;
          font-size: 45px;
        }
        .action-header-text {
          font-size: 28px;
        }
        .action-header-img {
          height: 192px;
        }
      }
    }
    .action-content {
      .action-block {
        .action-block-inner {
          padding-left: 0;
          .action-mobile-img-title {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            .action-block-circle-img {
              position: relative;
            }
            .action-block-title {
              padding-left: 20px;
            }
          }
          button {
            margin-left: auto;
            margin-right: auto;
          }
        }
        &.block-3 {
          .action-block-text {
            align-self: center;
          }
        }
      }
    }
    .action-footer {
      .action-footer-wrap {
        .action-footer-mobile-title {
          order: 1;
          width: 100%;
          font-size: 24px;
          text-align: left;
          padding-bottom: 0;
          line-height: 40px;
        }
        .action-footer-img {
          order: 2;
          width: 100%;
          height: 127px;
          background-position: center;
        }
        .block-text {
          order: 3;
          width: 100%;
          padding-left: 0;
        }
        .logo-wrap {
          .logo-vtb {
            width: 52px;
            margin-top: 16px;
          }
          .logo-spb {
            width: 102px;
            margin-top: 16px;
          }
          .logo-sber {
            width: 130px;
            margin-top: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 390px) {
    .action-footer {
      .logo-wrap {
        flex-direction: column;
        padding-left: 36px;
        .logo-vtb {
          width: 60px;
        }
        .logo-spb {
          width: 116px;
        }
        .logo-sber {
          width: 147px;
        }
      }
    }
  }
}

.tw-special-mode {
  .action-wrap {
    .action-header {
      background: none;
      color: #000;
      .action-header-title {
        top: 0;
      }
      .action-header-text {
        width: 92%;
      }
    }
    .action-content {
      background: none;
      button {
        border-color: #000;
        color: #000;
      }
      button:hover {
        background: #000;
      }
      .action-block {
        .block-text {
          width: 100%;
          .action-block-title,
          .action-block-subtitle {
            padding-left: 0;
          }
        }
        .action-block-list-circle li::before {
          background-color: #000;
          top: 8px;
        }
        .action-block-list-num li::before {
          color: #000;
          top: 2px;
          font-size: 18px;
        }
      }
    }
    .action-footer {
      .block-text {
        width: 100%;
      }
    }
  }
}
</style>
